import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import useAPI from "../../hooks/useAPI";
import logo2 from "../../imgs/logo_2.png";
import logo1 from "../../imgs/logo_1.png";
import logo0 from "../../imgs/logo_0.png";
import logoNav from "../../imgs/logo_nav.png";
import logoLogin from "../../imgs/Sending_logo_dark.png"

import { BsGear, BsChatDots, BsSun, BsMoon, BsShop } from "react-icons/bs";
import { IoMegaphoneOutline } from "react-icons/io5";
import { BiSolidChevronLeftCircle } from "react-icons/bi";
import { FaWpforms, FaMoon, FaSun } from "react-icons/fa";
import { PiUsersThree } from "react-icons/pi";
import { GiMechanicalArm } from "react-icons/gi";
import { AiOutlinePoweroff } from "react-icons/ai";

const Navigation = () => {
  const [mainMenu, setMainMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);
  const [isAdmin, setIsAdmin] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const { urlAPI, optPOST, optGET, optPUT, optDELETE, urlAPI_1, urlAPI_2, name_version_app, number_version_app } = useAPI();

  const companyId = localStorage.getItem('COMPANY_ID');

  useEffect(() => {
    const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
    if (
      roleDescription === "Administrador" ||
      roleDescription === "SuperAdmin"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

    const mode = localStorage.getItem("IS_DARK");
    if (mode) {
      setIsChecked(mode)
      const darkMode = mode === "true";
      var body = document.getElementById('body-dark')
      if(darkMode){
        body.classList.add("dark")
      }else{
        body.classList.remove("dark")
      }
    }
  }, []);

  const navigate = useNavigate();

  const logOut = () => {
    console.log("Removing USER_TOKEN because logout action navigation");
    localStorage.removeItem("USER_TOKEN");
    localStorage.removeItem("USER_NAME");
    localStorage.removeItem("USER_ROLEDESCRIPTION");
    localStorage.removeItem("USER_ROLEID");
    navigate("/");
  };

  const onChangeTheme = async () => {
    console.log('-----------------Ejecutando cambio de tema-----------------')
    setMainMenu(false)
    setIsChecked(!isChecked);
    var body = document.getElementById('body-dark')
    if(!isChecked){body.classList.add("dark")}
    else{body.classList.remove("dark")}

    const values = {
      isDark: !isChecked
    }
    try {
      // const url = urlAPI_2 + "companies/" + companyId;
      // const response = await fetch(url, optPUT(values));
      // const result = await response.json();

      const url = urlAPI_2 + "users/" + userId;
      const response = await fetch(url, optPUT(values));
      const result = await response.json();
      // console.log("Response of the API", result);
      console.log("........______ DARK ______.......", result.data);
      localStorage.setItem("IS_DARK", result.data.isDark);
      localStorage.setItem("THEME", result.data.isDark);
    } catch (error) {
      console.log(error);
    }
  }

  const location = useLocation();
  const currentPath = location.pathname;

  // const imgStyle = currentPath === '/home/chat' ? { height: '1em' } : {};

  return (
    <Fragment>
      <nav id="content-nav" className="main-header is-nav-chat" style={{ position: "fixed", width: "2.5%"}}>        
      {/* <nav id="content-nav" className={`main-header ${currentPath == "/home/chat" && "is-nav-chat"}`} style={{ position: "fixed", width: currentPath == "/home/chat" ? "2.5%" : "5%"}}>         */}
        <ul className="list-logo-nav mt-0">
          <div className="branding">
            <h1 className="title m-0">
              <Link to="/home" rel="home">
                <span>SendingUp</span>
                <i className="layer_0 w-100">
                  <img alt="logo0" src={logo0}/>
                </i>
                <i className="layer_1 w-100">
                  <img alt="logo1" src={logo1}/>
                </i>
                <i className="layer_2 w-100">
                  <img alt="logo2" src={logo2}/>
                </i>

              </Link>
            </h1>
            <Link id="logo-res-nav" to="/home" rel="home">
              {/* <img alt="logo_nav" src={logoNav} /> */}
              <img src={logoLogin} alt="logo" />
            </Link>
          </div>
        </ul>

        <ul className="list-options-nav m-0 is-module-chat">
        {/* <ul className={`list-options-nav m-0 ${currentPath == "/home/chat" && "is-module-chat"}`}> */}
          <li className="option-contacts">
            <a
              className={`menu-toggle `}
              href="javascript:;"
              title="Usuarios"
              onClick={() => {
                setActiveMenu("/home/contacts");
                var ht = document.getElementById("main-content");
                ht.classList.toggle("active");
                setMainMenu(true);
              }}
            >
              <PiUsersThree className="w-100" />
            </a>
          </li>
          {isAdmin && (
            <>
              <li className="option-templates">
                <a
                  className={`menu-toggle `}
                  href="javascript:;"
                  title="Ecommerce"
                  onClick={() => {
                    setActiveMenu("/home/ecommerce");
                    var ht = document.getElementById("main-content");
                    ht.classList.toggle("active");
                    setMainMenu(true);
                  }}
                >
                  <BsShop className="tbTemplate w-100" style={{stroke: "#6d7d88"}} />
                </a>
              </li>

              <li className="option-templates">
                <a
                  className={`menu-toggle `}
                  href="javascript:;"
                  title="Plantillas"
                  onClick={() => {
                    setActiveMenu("/home/templates");
                    var ht = document.getElementById("main-content");
                    ht.classList.toggle("active");
                    setMainMenu(true);
                  }}
                >
                  <FaWpforms className="tbTemplate w-100" style={{stroke: "#6d7d88"}} />
                </a>
              </li>
              
              <li className="option-assistant" style={{fontSize:"40px"}}>
                <a
                  className={`menu-toggle position-relative`}
                  href="javascript:;"
                  title="Automatizaciones"
                  onClick={() => {
                    setActiveMenu("/home/assistant");
                    var ht = document.getElementById("main-content");
                    ht.classList.toggle("active");
                    setMainMenu(true);
                  }}
                >
                  <GiMechanicalArm className="w-100" style={{stroke: "#6d7d88"}} />
                </a>
              </li>
              
              <li className="option-campaign">
                <a
                  className={`menu-toggle `}
                  href="javascript:;"
                  title="Campañas"
                  onClick={() => {
                    setActiveMenu("/home/campaign");
                    var ht = document.getElementById("main-content");
                    ht.classList.toggle("active");
                    setMainMenu(true);
                  }}
                >
                  <IoMegaphoneOutline className="w-100" />
                </a>
              </li>
            </>
          )}
          <li className="option-chat">
            <a
              className={`menu-toggle `}
              href="javascript:;"
              title="Chat"
              onClick={() => {
                setActiveMenu("/home/chat");
                var ht = document.getElementById("main-content");
                ht.classList.toggle("active");
                setMainMenu(true);
              }}
            >
              <BsChatDots className="w-100" />
            </a>
          </li>

          {isAdmin && (
            <li className="option-settings">
              <a
                className={`menu-toggle`}
                href="javascript:;"
                title="Configuraciones"
                onClick={() => {
                  setActiveMenu("/home/settings");
                  var ht = document.getElementById("main-content");
                  ht.classList.toggle("active");
                  setMainMenu(true);
                }}
              >
                <BsGear className="w-100" style={{stroke: "#6d7d88"}} />
              </a>
            </li>
          )}

          {currentPath == "/home/chat" &&
            <Header />
          }

          {/* <li>
            <a
              className={`menu-toggle `}
              href="javascript:;"
              title="Chat Bot"
              onClick={() => {
                setActiveMenu("/home/chat-bot");
                var ht = document.getElementById("main-content");
                ht.classList.toggle("active");
                setMainMenu(true);
              }}
            >
              <i className="icon-reddit w-100" />
            </a>
          </li>  */}
        </ul>

        <ul className="list-options-2-nav m-0">
          <li className="mt-2">
              <input
                id="dark"
                className="cmn-toggle cmn-toggle-round-flat"
                type="checkbox"
                name="dark"
                checked={isChecked}
                onChange={onChangeTheme}
              />
              <a
                className="menu-toggle"
                href="javascript:;"
                title="Cambiar tema"
                // style={{height: "auto"}}
              >
                {!isChecked ?
                  <label className="form-check-label ml-0 pointer" htmlFor="dark" style={{marginBottom: "0.2rem"}}>
                      <BsMoon className="ml-0" style={{fontSize: "1.9rem"}}/>
                  </label>
                :
                  <label className="form-check-label ml-0 pointer" htmlFor="dark" style={{marginBottom: "0.2rem"}}>
                      <BsSun className="ml-0" style={{fontSize: "1.9rem"}}/>
                  </label>
                }
              </a>
          </li>
          <li className="mt-2">
            <a
              className="menu-toggle"
              href="javascript:;"
              style={{height: "auto"}}
              title="Cerrar sesión"
              onClick={() => logOut()}
            >
              <AiOutlinePoweroff  style={{fontSize: "2.1rem", marginBottom: "0.2rem"}} />
            </a>
          </li>
            
        </ul>

        <ul className="version-app w-100">
          <li className="version-name">
            <h5>{name_version_app}</h5>
            <p style={{fontSize:"1rem", color:"#fff"}}>{number_version_app}</p>
          </li>
        </ul>
      </nav>

      {mainMenu && (
        <div className="submenus" style={{ position: "fixed", left: "2%"}}>
        {/* <div className="submenus" style={{ position: "fixed", left: currentPath == "/home/chat" ? "2%" : "5%"}}> */}
          {activeMenu === "/home/contacts" && (
            <nav className="products active" id="submenu">
              <span className="serif">Contactos</span>
              <ul>
                <li className="opt-contacts-contacts">
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/contacts"
                  >
                    Contactos
                  </Link>
                </li>
                {isAdmin && (<>
                  <li className="opt-contacts-additional-fields">
                    <Link
                      onClick={() => {
                        setMainMenu(false);
                        var ht = document.getElementById("main-content");
                        ht.classList.toggle("active");
                      }}
                      to="/home/additional-fields"
                    >
                      Campos adicionales
                    </Link>
                  </li>
                  <li className="opt-contacts-lists">
                    <Link
                      onClick={() => {
                        setMainMenu(false);
                        var ht = document.getElementById("main-content");
                        ht.classList.toggle("active");
                      }}
                      to="/home/lists"
                    >
                      Listas
                    </Link>
                  </li>
                  <li className="opt-contacts-tags hide-responsive">
                    <Link
                      onClick={() => {
                        setMainMenu(false);
                        var ht = document.getElementById("main-content");
                        ht.classList.toggle("active");
                      }}
                      to="/home/tags"
                    >
                      Etiquetas
                    </Link>
                  </li>
                  <li className="opt-prospects hide-responsive">
                    <Link
                      onClick={() => {
                        setMainMenu(false);
                        var ht = document.getElementById("main-content");
                        ht.classList.toggle("active");
                      }}
                      to="/home/prospects"
                    >
                      Prospectos
                    </Link>
                  </li>
                </>)}
              </ul>
            </nav>
          )}
          {activeMenu === "/home/additional-fields" && (
            <nav className="products active" id="submenu">
              <span className="serif">Navegación</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/additional-fields"
                  >
                    Campos adicionales
                  </Link>
                </li>
              </ul>
            </nav>
          )}
          {activeMenu === "/home/lists" && (
            <nav className="products active" id="submenu">
              <span className="serif">Navegación</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/lists"
                  >
                    Listas
                  </Link>
                </li>
              </ul>
            </nav>
          )}
          {activeMenu === "/home/settings" && (
            <nav className="products active" id="submenu">
              <span className="serif">Configuraciones</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/absentMessage"
                  >
                    Mensaje de ausencia
                  </Link>
                </li>
                <li className="hide-responsive">
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/businessHours"
                  >
                    Horario comercial
                  </Link>
                </li>
                <li className="hide-responsive">
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/chatClosures"
                  >
                    Cierres automáticos
                  </Link>
                </li>
                <li className="hide-responsive">
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/historicalChats"
                  >
                    Históricos
                  </Link>
                </li>                                
              </ul>
            </nav>
          )}
          {activeMenu === "/home/assistant" && (
            <nav className="products active" id="submenu">
              <span className="serif">Automatizaciones</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/assistants"
                  >
                    Asistentes AI
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/botsConstructor"
                  >
                    Constructor de Bots
                  </Link>
                </li>
              </ul>
            </nav>
          )}
          {activeMenu === "/home/ecommerce" && (
            <nav className="products active" id="submenu">
              <span className="serif">Ecommerce</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/catalog"
                  >
                    Catálogo
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/orders"
                  >
                    Pedidos
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/paymentMethods"
                  >
                    Medios de pago
                  </Link>
                </li>
              </ul>
            </nav>
          )}
          {activeMenu === "/home/templates" && (
            <nav className="products active" id="submenu">
              <span className="serif">Navegación</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/templates"
                  >
                    Plantillas
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/flows"
                  >
                    Flujos
                  </Link>
                </li>
              </ul>
            </nav>
          )}
          {activeMenu === "/home/campaign" && (
            <nav className="products active" id="submenu">
              <span className="serif">Navegación</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/campaign"
                  >
                    Campañas
                  </Link>
                </li>
              </ul>
            </nav>
          )}
          {activeMenu === "/home/chat" && (
            <nav className="products active" id="submenu">
              <span className="serif">Navegación</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/chat"
                  >
                    Chat
                  </Link>
                </li>
              </ul>
            </nav>
          )}
          {/* activeMenu === "/home/chat-bot" && (
            <nav className="products active" id="submenu">
              <span className="serif">Navegación</span>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setMainMenu(false);
                      var ht = document.getElementById("main-content");
                      ht.classList.toggle("active");
                    }}
                    to="/home/chat-bot"
                  >
                    ChatBot
                  </Link>
                </li>
              </ul>
            </nav>
                  )*/}
          <a className="btn btn-link d-flex" href="javascript:;"
            onClick={() => {
              setMainMenu(false);
              var ht = document.getElementById("main-content");
              ht.classList.toggle("active");
              setActiveMenu(window.location.pathname);
            }}
          >
            <BiSolidChevronLeftCircle style={{ fontSize: "23px" }} />{" "}Cerrar
          </a>
        </div>
      )}
    </Fragment>
  );
};

export default Navigation;
