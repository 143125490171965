import { queryClient, QueryClientProvider } from "./components/plugins/queryClient";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import React from "react";
import { es } from "dayjs/locale/es";
//Routing
import { HashRouter as Router, Routes, Route } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Components
import Login from "./components/content/Login";
import Layout from "./components/layout/Layout";
import Dashboard from "./components/layout/Dashboard";
import Persons from "./components/content/Persons";
import UsersSystem from "./components/content/UsersSystem";
import Person from "./components/content/Person";
import Lists from "./components/content/Lists";
import List from "./components/content/List";
import Templates from "./components/content/Templates";
import Flows from "./components/content/Flows";
import Catalog from "./components/content/Catalog";
import Orders from "./components/content/Orders";
import OrderDetail from "./components/content/OrderDetail";
import PaymentMethods from "./components/content/PaymentMethods";
import Campaign from "./components/content/Campaign";
import NotFound from "./components/plugins/NotFound";
import ForgotPassword from "./components/content/ForgotPassword";
import Reset from "./components/content/Reset";
import AdditionalFields from "./components/content/AdditionalFields";
import CampaignReport from "./components/content/CampaignReport";
import Prospects from "./components/content/Prospects";
//Context
import ListsState from "./context/Lists/ListsState";
import Companies from "./components/content/Companies";
import Template from "./components/content/Template";
import MyData from "./components/content/MyData";
import ConsumptionPayments from "./components/content/ConsumptionPayments";
import TemplateWhatsapp from "./components/content/TemplateWhatsapp";
import TemplateWhatsappEdit from "./components/content/TemplateWhatsappEdit";
import Chat from "./components/content/Chat";
import Support from "./components/content/Support";
import CreatePassword from "./components/content/CreatePassword";
import SupportAdmin from "./components/content/SupportAdmin";
import SupportDetail from "./components/content/SupportDetail";
import ChatBot from "./components/content/ChatBot";
import PaymentSummary from "./components/content/PaymentSummary"
import Tags from "./components/content/Tags";
import AbsentMessage from "./components/content/AbsentMessage";
import BusinessHours from "./components/content/BusinessHours";
import ConsumptionByCompany from "./components/content/ConsumptionByCompany"
import ChatClosures
 from "./components/content/ChatClosures";
import AssistantAI from "./components/content/AssistantAI"
import BotsConstructor from "./components/content/BotsConstructor"
import HistoricalChats from "./components/content/HistoricalChats";
//Fontello
import "./fontello/css/fontello.css";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <Router>
      <ListsState>
        <Routes history="hashHistory">
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/home/reset/:token" element={<Reset />} />
          <Route exact path="/home/createPassword/:token" element={<CreatePassword />} />
          <Route exact path="/admin" element={<Companies />} />
          <Route exact path="/supportAdmin" element={<SupportAdmin />} />
          <Route exact path="/consumptionByCompany" element={<ConsumptionByCompany />} />
          <Route path="*" element={<NotFound />} />
          <Route exact path="/home" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route exact path="/home/agents" element={<UsersSystem />} />
            <Route exact path="/home/contacts" element={<Persons />} />
            <Route exact path="/home/contact/:id" element={<Person />} />
            <Route exact path="/home/additional-fields" element={<AdditionalFields />} />
            <Route exact path="/home/lists" element={<Lists />} />
            <Route exact path="/home/list/:id_param" element={<List />} />
            <Route exact path="/home/tags" element={<Tags />} />
            <Route exact path="/home/templates" element={<Templates />} />
            <Route exact path="/home/flows" element={<Flows />} />
            <Route exact path="/home/catalog" element={<Catalog />} />
            <Route exact path="/home/orders" element={<Orders />} />
            <Route exact path="/home/order/:id" element={<OrderDetail />} />
            <Route exact path="/home/paymentMethods" element={<PaymentMethods />} />
            <Route exact path="/home/template/:id" element={<Template />} />
            <Route exact path="/home/campaign" element={<Campaign />} />
            <Route exact path="/home/campaignReport/:id" element={<CampaignReport />} />
            <Route exact path="/home/myData" element={<MyData />} />
            <Route exact path="/home/businessHours" element={<BusinessHours />} />
            <Route exact path="/home/absentMessage" element={<AbsentMessage />} />
            <Route exact path="/home/chatClosures" element={<ChatClosures />} />
            <Route exact path="/home/assistantAI" element={<AssistantAI />} />
            <Route exact path="/home/botsConstructor" element={<BotsConstructor />} />
            <Route exact path="/home/consumptionPayments" element={<ConsumptionPayments />} />
            <Route exact path="/home/templateWhatsapp" element={<TemplateWhatsapp />} />
            <Route exact path="/home/templateWhatsappEdit/:id" element={<TemplateWhatsappEdit />} />
            <Route exact path="/home/chat" element={<Chat />} />
            <Route exact path="/home/support" element={<Support />} />
            <Route exact path="/home/supportDetail/:id" element={<SupportDetail />}/>
            <Route exact path="/home/chat-bot" element={<ChatBot />} />
            <Route exact path="/home/paymentSummary" element={<PaymentSummary />} />
            <Route exact path="/home/historicalChats" element={<HistoricalChats />} />
            <Route exact path="/home/prospects" element={<Prospects />} />
          </Route>
        </Routes>
      </ListsState>
    </Router>
    {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />} */}
    </QueryClientProvider>
  );
}

export default App;
